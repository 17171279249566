window.$ = window.jQuery = require('jquery');
// require('popperjs');
require('bootstrap');

var trumbowyg = require('trumbowyg');
require('trumbowyg/dist/langs/zh_cn.js');

$(document).ready(function() {

  /* Init Trumbowyg WYSIWYG editor (English / default) */
  $('.trumbowyg').trumbowyg({
    lang: 'en',
    svgPath: '/svg/trumbowyg-icons.svg',
    removeformatPasted: true,
    autogrow: true,
    btns: [
        ['viewHTML'],
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['link'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen']
    ]
  });

  /* Init Trumbowyg WYSIWYG editor (simplified Chinese) */
  $('.trumbowyg-zh').trumbowyg({
    lang: 'zh_cn',
    svgPath: '/svg/trumbowyg-icons.svg',
    removeformatPasted: true,
    autogrow: true,
    btns: [
        ['viewHTML'],
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['link'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen']
    ]
  });

  // $('.lightbox').magnificPopup({type:'image'});

    /*

    $('ul.color-list').on('click','.deleteColor',function(e) {
    // $('.deleteColor').click(function(e){

      e.preventDefault();

      var color_id = $(this).data("color-id");
      var color_group_id = $(this).data("color-group-id");

      $.ajax({
           url: "/admin/color-group/" + color_group_id + "/destroy-color/" + color_id,
           type: "GET",
           success: function (data) {
             $('#color-list-item-' + color_id).remove();
           },
           error: function(xhr, ajaxOptions, thrownError){
              alert('Error deleting color.');
           },
           timeout : 15000//timeout of the ajax call
      });

    });

    */

    /*

    $('.addColor').submit(function(e){

      e.preventDefault();

      var color_group_id = $(this).find('input[name="color_group_id"]').val();
      var title = $(this).find('input[name="title"]').val();

      $.ajax({
          data: {
            color_group_id: color_group_id,
            title: title
          },
          url: '/admin/color-group/' + color_group_id + '/create-color',
          type: 'POST',
          beforeSend: function (request) {
            return request.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'));
          },
          success: function(response){

            $('#add-color-form-input-' + color_group_id).val(''); // clear form field

            // console.log(response);
            resp = JSON.parse(response);
            $('#color-list-' + color_group_id).append('<li id="color-list-item-' + resp.id + '"></li>');
            $('#color-list-item-' + resp.id).text(title);
            $('#color-list-item-' + resp.id).prepend('<a href="#" class="deleteColor badge badge-primary badge-sm" data-color-group-id="' + color_group_id + '" data-color-id="' + resp.id + '">X</a>');

          },
          timeout : 15000//timeout of the ajax call
      });

    });
    */


    // Marks image for deletion
    $('.product-image-delete').click(function(e) {

      e.preventDefault();

      var product_id = $(this).data('product-id');
      var image_no = $(this).data('image-no');

      $('#image-' + image_no).attr("src","/assets/product-images/no-image.png");
      $('#image-' + image_no + '-controls-hasphoto').hide();
      $('#image-' + image_no + '-controls-nophoto').show();
      $('#image-' + image_no + '-delete').val(1);
      $('#image-' + image_no + '-replacewith').val('');

    });

    // Opens file select dialog
    $('.product-image-selectfile').click(function(e) {

      e.preventDefault();

      var product_id = $(this).data('product-id');
      var image_no = $(this).data('image-no');

      $('#image-' + image_no + '-fileinput').focus().trigger('click');

    });

    // When file is selected, upload image
    $('input.image-fileinput').change(function(e){

      e.stopPropagation();
      e.preventDefault();

      var product_id = $(this).data('product-id');
      var image_no = $(this).data('image-no');
      var file = e.target.files[0];

      $('#image-' + image_no + '-controls-hasphoto').hide();
      $('#image-' + image_no + '-controls-nophoto').hide();
      $('#image-' + image_no + '-controls-uploading').show();

      var fd = new FormData();
      fd.append("image_no", image_no);
      fd.append('imgfile', file);

      $.ajax({
        data: fd,
        processData: false,
        contentType: false,
        url:'/admin/product/' + product_id + '/ajax-image-upload',
        type:'POST',
        beforeSend: function (request) {
          return request.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'));
        },
        success: function(response){
          $('#image-' + image_no + '-controls-uploading').hide();
          $('#image-' + image_no + '-controls-hasphoto').show();
          let filename = response.filename;
          let img_uri = response.img_uri;
          let nocache = Math.floor((Math.random() * 99999) + 1);
          $('#image-' + image_no).attr("src",img_uri + '?nocache=' + nocache);
          $('#image-' + image_no + '-replacewith').val(filename);
        },
        error: function(xhr, ajaxOptions, thrownError){
           alert('Error uploading image - session expired. Please reload page.');
        },
      });

    });


});
